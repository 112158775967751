@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
:root {
    --header-height: 60px;
}
header {
    height: var(--header-height);
}

.fixed-top-bar {
    padding-top: var(--header-height);
}

.outline-none {
  outline: none !important;
}

.fit-cover {
  object-fit: cover !important;
  object-position: center !important;
}

a {
    text-decoration: none !important;
}

.fit-contain {
  object-fit: contain !important;
  object-position: center !important;
}

/* Cursor */

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-no-drop {
  cursor: no-drop !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}

/* BOX */

.box-sm {
  display: block !important;
  width: 55px !important;
  height: 55px !important;
}

/* Hover */

.hover-border {
  transition: all 0.1s linear !important;
}

.hover-border:hover {
  border: 1px solid #1266f1 !important;
  transition: all 0.1s linear !important;
}

.bg-hover {
  transition: all 0.1s ease-in-out !important;
}

.bg-hover:hover {
  background-color: #e0e0e0 !important;
  transition: all 0.1s ease-in-out !important;
}

.scale {
  transition: all 0.3s ease !important;
}

.scale:hover img {
  -webkit-transform: scale(1.1) !important;
          transform: scale(1.1) !important;
  transition: all 0.3s ease !important;
}

.hover-underline:hover {
  text-decoration: underline;
}

.hover-primary {
  transition: all 0.1s ease-in-out !important;
}

.hover-primary:hover {
  color: #1266f1 !important;
}

/* Wrap */

.text-wrap {
  white-space: wrap !important;
}

/* Animation */

.hover-to-show + div {
  display: flex !important;
  position: absolute !important;
  top: 100% !important;
  left: 0% !important;
  justify-content: center !important;
  align-items: center !important;
}

.hover-to-show:hover + div,
.hover-to-show + div:hover {
  -webkit-animation: edit-slide-up 0.2s ease-in-out 1 !important;
          animation: edit-slide-up 0.2s ease-in-out 1 !important;
  top: 0% !important;
}

.edit-name-modal-content {
  -webkit-animation: edit-modal-pop-up 0.1s ease-in-out 1;
          animation: edit-modal-pop-up 0.1s ease-in-out 1;
}

.blink {
  -webkit-animation: blink-dot 2s ease-in-out infinite;
          animation: blink-dot 2s ease-in-out infinite;
}

.slide-up-network-indicator {
  -webkit-animation: slide-up-network-indicator 0.5s ease-in-out 1;
          animation: slide-up-network-indicator 0.5s ease-in-out 1;
}

.chevron-rotate-down {
  -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
  transition: all 0.2s linear;
}

.chevron-rotate-up {
  -webkit-transform: rotateZ(-180deg);
          transform: rotateZ(-180deg);
  transition: all 0.2s linear;
}

.resize-none {
  resize: none;
}

.pop-up {
  -webkit-animation: pop-up 0.2s ease-in-out 1;
          animation: pop-up 0.2s ease-in-out 1;
}

.pop-down {
  -webkit-animation: pop-down 0.2s ease-in-out 1;
          animation: pop-down 0.2s ease-in-out 1;
}

.control-arrow {
  width: 15%;
}

.remove-margin-bottom p {
    margin-bottom: 0px;
}

.text-yellow {
    color: #ffc107;
}

@-webkit-keyframes slide-up-network-indicator {
  0% {
    bottom: -10%;
  }

  100% {
    bottom: 0%;
  }
}

@keyframes slide-up-network-indicator {
  0% {
    bottom: -10%;
  }

  100% {
    bottom: 0%;
  }
}

@-webkit-keyframes blink-dot {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes blink-dot {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes edit-modal-pop-up {
  0% {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes edit-modal-pop-up {
  0% {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes edit-slide-up {
  0% {
    top: 100%;
  }

  100% {
    top: 0%;
  }
}

@keyframes edit-slide-up {
  0% {
    top: 100%;
  }

  100% {
    top: 0%;
  }
}

@-webkit-keyframes pop-up {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes pop-up {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes pop-down {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes pop-down {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@media only screen and (min-width: 1024px) {
    .search-control {
        height: 41px !important;
    }

    .search-btn {
        height: 41px !important;
    }

    .cat-box {
        height: 50px !important;
    }

    .img-xs {
        width: 50px !important;
        height: 50px !important;
    }

    .img-sm {
        width: 65px !important;
        height: 65px !important;
    }

    .img-md {
        width: 112px !important;
        height: 112px !important;
    }

    .img-nor {
        width: 150px !important;
        height: 150px !important;
    }

    .img-lg {
        width: 400px !important;
        height: 400px !important;
    }

    .mobile {
        margin-top: -60px !important;
    }

    .count-box {
        width: 40px !important;
        height: 30px !important;
    }

    .hover-scale {
        transition: 0.1s all linear !important;
    }

    .hover-scale:hover {
        -webkit-transform: scale(1.05) !important;
                transform: scale(1.05) !important;
        transition: 0.1s all linear !important;
    }

    .prod-img-box {
        display: block !important;
        width: 320px !important;
        height: 420px !important;
    }

    .valid-thru {
        width: 80px !important;
    }

    .valid-thru:focus {
        transition: all .1s linear !important;
        border-color: #1266f1 !important;
        box-shadow: inset 0px 0px 0px 1px #1266f1 !important;
    }

    .prof-side-active {
        transition: all 0.3s linear !important;
        background-color: #1266f1 !important;
    }

    .prof-side-active i {
        color: #ffffff !important;
    }

    .prof-side-active span {
        color: #ffffff !important;
    }

    .profile-body {
        height: calc(100vh - 58px) !important;
    }

    .vh-100-top {
        height: calc(100vh - 90px);
    }

    .vh-100-top-admin {
        height: calc(100vh - 52px);
    }

    .login-group {
        position: relative !important;
        height: 60px !important;
        width: 100% !important;
        overflow: hidden !important;
    }

    .login-control {
        border: none !important;
        outline: none !important;
        width: 100% !important;
        height: 100% !important;
        padding-top: 20px !important;
    }

    .login-label {
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        bottom: 0% !important;
        left: 0% !important;
        pointer-events: none !important;
        border-bottom: 1px solid gray !important;
    }

    .login-label::after {
        content: '';
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        bottom: -1px !important;
        left: 0% !important;
        border-bottom: 2px solid #1266f1 !important;
        -webkit-transform: translateX(-101%) !important;
                transform: translateX(-101%) !important;
        transition: all 0.2s linear !important;
    }

    .login-control:focus+.login-label::after,
    .login-control:valid+.login-label::after {
        -webkit-transform: translateX(0%) !important;
                transform: translateX(0%) !important;
        transition: all 0.2s linear !important;
    }

    .login-label-name {
        position: absolute !important;
        bottom: 5px !important;
        left: 0% !important;
        transition: all 0.2s linear !important;
        font-size: 15px !important;
    }

    .login-control:focus+.login-label .login-label-name,
    .login-control:valid+.login-label .login-label-name {
        -webkit-transform: translateY(-150%) !important;
                transform: translateY(-150%) !important;
        transition: all 0.2s linear !important;
        font-size: 12px !important;
    }

    .login-label-danger {
        border-bottom: 1px solid #f93154 !important;
    }

    .login-label-danger::after {
        border-bottom: 2px solid #f93154 !important;
    }
}
@media only screen and (min-width: 640px) and (max-width: 1024px) {
    .search-control {
        height: 41px !important;
    }

    .search-btn {
        height: 41px !important;
    }

    .cat-box {
        height: 50px !important;
    }

    .img-xs {
        width: 50px !important;
        height: 50px !important;
    }

    .img-sm {
        width: 65px !important;
        height: 65px !important;
    }

    .img-md {
        width: 112px !important;
        height: 112px !important;
    }

    .img-nor {
        width: 150px !important;
        height: 150px !important;
    }

    .img-lg {
        width: 400px !important;
        height: 400px !important;
    }

    .mobile {
        margin-top: -60px !important;
    }

    .count-box {
        width: 40px !important;
        height: 30px !important;
    }

    .hover-scale {
        transition: 0.1s all linear !important;
    }

    .hover-scale:hover {
        -webkit-transform: scale(1.05) !important;
                transform: scale(1.05) !important;
        transition: 0.1s all linear !important;
    }

    .prod-img-box {
        width: 100% !important;
        height: 420px !important;
    }

    .valid-thru {
        width: 80px !important;
    }

    .valid-thru:focus {
        transition: all .1s linear !important;
        border-color: #1266f1 !important;
        box-shadow: inset 0px 0px 0px 1px #1266f1 !important;
    }

    .prof-side-active {
        transition: all 0.3s linear !important;
        background-color: #1266f1 !important;
    }

    .prof-side-active i {
        color: #ffffff !important;
    }

    .prof-side-active span {
        color: #ffffff !important;
    }

    .profile-body {
        height: calc(100% - 57px) !important;
    }

    .cart-overlay {
        z-index: 4999 !important;
        height: 100vh !important;
    }

    .cart-overlay::after {
        content: '' !important;
        width: 100% !important;
        height: 100% !important;
        display: block !important;
        top: 0 !important;
        left: 0 !important;
        background-color: #00000095 !important;
    }

    .prod-carousel-container {
        height: 500px !important;
    }

    .prod-slide-container {
        height: 500px !important;
    }

    .login-group {
        position: relative !important;
        height: 60px !important;
        width: 100% !important;
        overflow: hidden !important;
    }

    .login-control {
        border: none !important;
        outline: none !important;
        width: 100% !important;
        height: 100% !important;
        padding-top: 20px !important;
    }

    .login-label {
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        bottom: 0% !important;
        left: 0% !important;
        pointer-events: none !important;
        border-bottom: 1px solid gray !important;
    }

    .login-label::after {
        content: '';
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        bottom: -1px !important;
        left: 0% !important;
        border-bottom: 2px solid #1266f1 !important;
        -webkit-transform: translateX(-101%) !important;
                transform: translateX(-101%) !important;
        transition: all 0.2s linear !important;
    }

    .login-control:focus+.login-label::after,
    .login-control:valid+.login-label::after {
        -webkit-transform: translateX(0%) !important;
                transform: translateX(0%) !important;
        transition: all 0.2s linear !important;
    }

    .login-label-name {
        position: absolute !important;
        bottom: 5px !important;
        left: 0% !important;
        transition: all 0.2s linear !important;
        font-size: 15px !important;
    }

    .login-control:focus+.login-label .login-label-name,
    .login-control:valid+.login-label .login-label-name {
        -webkit-transform: translateY(-150%) !important;
                transform: translateY(-150%) !important;
        transition: all 0.2s linear !important;
        font-size: 12px !important;
    }

    .login-label-danger {
        border-bottom: 1px solid #f93154 !important;
    }

    .login-label-danger::after {
        border-bottom: 2px solid #f93154 !important;
    }

    .vh-100-top {
        height: calc(100vh - 90px);
    }

    .vh-100-top-admin {
        height: calc(100vh - 52px);
    }
}
@media only screen and (max-width: 640px) {
    .search-control {
        height: 35px !important;
    }

    .search-btn {
        height: 35px !important;
    }

    .cat-box {
        height: 50px !important;
    }

    .img-xs {
        width: 50px !important;
        height: 50px !important;
    }

    .img-sm {
        width: 65px !important;
        height: 65px !important;
    }

    .img-md {
        width: 112px !important;
        height: 112px !important;
    }

    .img-nor {
        width: 150px !important;
        height: 150px !important;
    }

    .img-lg {
        width: 400px !important;
        height: 400px !important;
    }

    .mobile {
        margin-top: -60px !important;
    }

    .count-box {
        width: 40px !important;
        height: 30px !important;
    }

    .hover-scale {
        transition: 0.1s all linear !important;
    }

    .hover-scale:hover {
        -webkit-transform: scale(1.05) !important;
                transform: scale(1.05) !important;
        transition: 0.1s all linear !important;
    }

    .prod-img-box {
        width: 100% !important;
        height: 300px !important;
    }

    .valid-thru {
        width: 80px !important;
    }

    .valid-thru:focus {
        transition: all .1s linear !important;
        border-color: #1266f1 !important;
        box-shadow: inset 0px 0px 0px 1px #1266f1 !important;
    }

    .prof-side-active {
        transition: all 0.3s linear !important;
        background-color: #1266f1 !important;
    }

    .prof-side-active i {
        color: #ffffff !important;
    }

    .prof-side-active span {
        color: #ffffff !important;
    }

    .sidenavbar-overlay {
        z-index: 4999 !important;
        height: 100vh !important;
    }

    .sidenavbar-overlay::after {
        content: '' !important;
        width: 100% !important;
        height: 100% !important;
        display: block !important;
        top: 0 !important;
        left: 0 !important;
        background-color: #00000095 !important;
    }

    .prod-carousel-container {
        height: 300px !important;
    }

    .prod-slide-container {
        height: 300px !important;
    }


    .login-group {
        position: relative !important;
        height: 60px !important;
        width: 100% !important;
        overflow: hidden !important;
    }

    .login-control {
        border: none !important;
        outline: none !important;
        width: 100% !important;
        height: 100% !important;
        padding-top: 20px !important;
    }

    .login-label {
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        bottom: 0% !important;
        left: 0% !important;
        pointer-events: none !important;
        border-bottom: 1px solid gray !important;
    }

    .login-label::after {
        content: '';
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        bottom: -1px !important;
        left: 0% !important;
        border-bottom: 2px solid #1266f1 !important;
        -webkit-transform: translateX(-101%) !important;
                transform: translateX(-101%) !important;
        transition: all 0.2s linear !important;
    }

    .login-control:focus+.login-label::after,
    .login-control:valid+.login-label::after {
        -webkit-transform: translateX(0%) !important;
                transform: translateX(0%) !important;
        transition: all 0.2s linear !important;
    }

    .login-label-name {
        position: absolute !important;
        bottom: 5px !important;
        left: 0% !important;
        transition: all 0.2s linear !important;
        font-size: 15px !important;
    }

    .login-control:focus+.login-label .login-label-name,
    .login-control:valid+.login-label .login-label-name {
        -webkit-transform: translateY(-150%) !important;
                transform: translateY(-150%) !important;
        transition: all 0.2s linear !important;
        font-size: 12px !important;
    }

    .login-label-danger {
        border-bottom: 1px solid #f93154 !important;
    }

    .login-label-danger::after {
        border-bottom: 2px solid #f93154 !important;
    }
}
.side-nav-bar-wrapper {
    position: fixed;
    top: 0%;
    left: 120%;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
    z-index: 1040;
}

.side-nav-bar-wrapper.open {
    -webkit-animation: side-nav-bar-wrapper-open .3s;
            animation: side-nav-bar-wrapper-open .3s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    left: 0%;
}

.side-nav-bar-wrapper.close {
    -webkit-animation: side-nav-bar-wrapper-close .4s;
            animation: side-nav-bar-wrapper-close .4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.side-nav-bar {
    width: 250px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 0%;
    right: -120%;
    z-index: 1050;
}

.side-nav-bar.open {
    -webkit-animation: side-nav-bar-open .3s;
            animation: side-nav-bar-open .3s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    right: 0%;
}

.side-nav-bar.close {
    -webkit-animation: side-nav-bar-close .4s;
            animation: side-nav-bar-close .4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    right: -120%;
}

.side-nav-bar .active {
    background-color: #dc3545;
    color: #fff !important;
}

@-webkit-keyframes side-nav-bar-open {
    0% {
        right: -120%;
    }

    100% {
        right: 0%;
    }
}

@keyframes side-nav-bar-open {
    0% {
        right: -120%;
    }

    100% {
        right: 0%;
    }
}

@-webkit-keyframes side-nav-bar-close {
    0% {
        right: 0%;
    }

    100% {
        right: -120%;
    }
}

@keyframes side-nav-bar-close {
    0% {
        right: 0%;
    }

    100% {
        right: -120%;
    }
}

@-webkit-keyframes side-nav-bar-wrapper-open {
    0% {
        background-color: rgba(0, 0, 0, 0);
    }

    100% {
        background-color: rgba(0, 0, 0, .5);
    }
}

@keyframes side-nav-bar-wrapper-open {
    0% {
        background-color: rgba(0, 0, 0, 0);
    }

    100% {
        background-color: rgba(0, 0, 0, .5);
    }
}

@-webkit-keyframes side-nav-bar-wrapper-close {
    0% {
        background-color: rgba(0, 0, 0, .5);
        left: 0%;
    }

    99.99% {
        background-color: rgba(0, 0, 0, 0);
        left: 0%;
    }

    100% {
        background-color: rgba(0, 0, 0, 0);
        left: 120%;
    }
}

@keyframes side-nav-bar-wrapper-close {
    0% {
        background-color: rgba(0, 0, 0, .5);
        left: 0%;
    }

    99.99% {
        background-color: rgba(0, 0, 0, 0);
        left: 0%;
    }

    100% {
        background-color: rgba(0, 0, 0, 0);
        left: 120%;
    }
}
body {
    margin: 0;
    font-family: 'Poppins', 'Roboto', 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield !important;
}
.notification-wrapper {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 300px;
    z-index: 6000;
}

.notification-item {
    -webkit-animation: SlideLeft 0.4s;
            animation: SlideLeft 0.4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    width: 300px;
    font-size: 14px;
}

@-webkit-keyframes SlideLeft {
    0% {
        margin-left: 120%;
    }

    100% {
        margin-left: 0;
    }
}

@keyframes SlideLeft {
    0% {
        margin-left: 120%;
    }

    100% {
        margin-left: 0;
    }
}

@-webkit-keyframes SlideRight {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: 120%;
    }
}

@keyframes SlideRight {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: 120%;
    }
}

.notification-item.exit {
    -webkit-animation: SlideRight 0.4s;
            animation: SlideRight 0.4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.notification-item .bar {
    height: 1px;
    background-color: #fff;
}
