@import "./variables.css";

header {
    height: var(--header-height);
}

.fixed-top-bar {
    padding-top: var(--header-height);
}

.outline-none {
  outline: none !important;
}

.fit-cover {
  object-fit: cover !important;
  object-position: center !important;
}

a {
    text-decoration: none !important;
}

.fit-contain {
  object-fit: contain !important;
  object-position: center !important;
}

/* Cursor */

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-no-drop {
  cursor: no-drop !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}

/* BOX */

.box-sm {
  display: block !important;
  width: 55px !important;
  height: 55px !important;
}

/* Hover */

.hover-border {
  transition: all 0.1s linear !important;
}

.hover-border:hover {
  border: 1px solid #1266f1 !important;
  transition: all 0.1s linear !important;
}

.bg-hover {
  transition: all 0.1s ease-in-out !important;
}

.bg-hover:hover {
  background-color: #e0e0e0 !important;
  transition: all 0.1s ease-in-out !important;
}

.scale {
  transition: all 0.3s ease !important;
}

.scale:hover img {
  transform: scale(1.1) !important;
  transition: all 0.3s ease !important;
}

.hover-underline:hover {
  text-decoration: underline;
}

.hover-primary {
  transition: all 0.1s ease-in-out !important;
}

.hover-primary:hover {
  color: #1266f1 !important;
}

/* Wrap */

.text-wrap {
  white-space: wrap !important;
}

/* Animation */

.hover-to-show + div {
  display: flex !important;
  position: absolute !important;
  top: 100% !important;
  left: 0% !important;
  justify-content: center !important;
  align-items: center !important;
}

.hover-to-show:hover + div,
.hover-to-show + div:hover {
  animation: edit-slide-up 0.2s ease-in-out 1 !important;
  top: 0% !important;
}

.edit-name-modal-content {
  animation: edit-modal-pop-up 0.1s ease-in-out 1;
}

.blink {
  animation: blink-dot 2s ease-in-out infinite;
}

.slide-up-network-indicator {
  animation: slide-up-network-indicator 0.5s ease-in-out 1;
}

.chevron-rotate-down {
  transform: rotateZ(0deg);
  transition: all 0.2s linear;
}

.chevron-rotate-up {
  transform: rotateZ(-180deg);
  transition: all 0.2s linear;
}

.resize-none {
  resize: none;
}

.pop-up {
  animation: pop-up 0.2s ease-in-out 1;
}

.pop-down {
  animation: pop-down 0.2s ease-in-out 1;
}

.control-arrow {
  width: 15%;
}

.remove-margin-bottom p {
    margin-bottom: 0px;
}

.text-yellow {
    color: #ffc107;
}

@keyframes slide-up-network-indicator {
  0% {
    bottom: -10%;
  }

  100% {
    bottom: 0%;
  }
}

@keyframes blink-dot {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes edit-modal-pop-up {
  0% {
    transform: scale(0.3);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes edit-slide-up {
  0% {
    top: 100%;
  }

  100% {
    top: 0%;
  }
}

@keyframes pop-up {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pop-down {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
